

#support{
    background: #030570; 
    background: -webkit-linear-gradient(to right, #9da7d4, #1d016b);
    background: linear-gradient(to right, #b4bcd4, #2a0181);
}

.support-main{
    background: #030570; 
background: -webkit-linear-gradient(to right, #9da7d4, #1d016b); 
background: linear-gradient(to right, #b4bcd4, #2a0181);
}


.sub-container{
    /* border: 1px solid red; */
    position: relative;
    width: 100%;
    height: 130vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 60px; */
}

.item{
    /* border: 1px solid white; */
    width: 100%;
    height: 650px;
    max-width: 1220px;
    /* border-radius: 20px;  */
    /* box-shadow: 0 0 20px 10px rgba(0, 0, 0, .4); */
    overflow: hidden;
    grid-template-columns: repeat(2, 1fr);
    /* display: grid; */
    z-index: 1000;
    margin-top: 70px;
}

.address-para-one{
    color: gray;
}

.contact{
    background: #fff;
}

.submit-form{
    background: #8f50ec;
}

.first-text{
    color: #8f50ec;
    padding-left: 20px;
    padding-top: 20px;
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}

.image{
    height: 350px;
    width: 350px;
    margin: 0 auto;
    /* border: 1px solid red; */
    display: flex;
}

.social-links{
    /* border: 1px solid red; */
    margin-top: 25px;
    /* margin-left: 10px; */
    text-align: center;
}

.social-media{
    display: flex;
    list-style: none;
}

.social-links ul{
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
}

.social-links ul li{
    padding-left: 15px;
    font-size: 24px;
    margin-top: 10px;   
    /* border: 1px solid red; */
}

.social-i{
    background: #8f50ec;
    padding: 5px;
    border-radius: 5px;
    transition: .3s;
    color: #fff;
}

.social-media .social-i:hover{
    background: #fff;
    color: #8f50ec;
}

.second-text{
    font-weight: 500;
    color: #8f50ec;
    padding-left: 20px;
}

.third-text{
    font-size: 25px;
    position: relative;
    top: 20px;
    left: 50px;
    padding-top: 10px;
    color: #fff;
}

form{
    padding: 0 50px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.input-box{
    height: 40px;
    width: 70%;
    margin: 30px 0;
    position: relative;
}

.input{
    width: 100%;
    height: 100%;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
    outline: none;
    padding: 20px;
    color: #fff;
    font-size: 16px;
}

.input-box label{
    position: absolute;
    top: 50%;
    left: 10px;
    padding-left: 10px;
    transform: translateY(-50%);
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    transition: .3s;
}

.input-box .input:focus ~ label, .input-box .input:valid ~ label{
    top: -1px;
    left: 10px;
    background: #8f50ec;
    font-weight: 500;
    font-size: 12px;
    padding: 5px;
}

.text-area-sub{
    resize: none;
    height: 150px !important;
    overflow: auto;
    padding-top: 10px;
    /* border: 1px solid red; */
    border-radius: 5px;
    width: 100%;
    text-indent: 10px;
}

.btn{
    position: relative;
    top: 100px;
    background: #fff;
    outline: none;
    border: none;
    border-radius: 4px;
    height: 45px;
    width: 30%;
    font-size: 16px;
    color: #8f50ec;
    font-weight: 500;
}

@media(max-width:850px){
    .item
    {
        grid-template-columns: 1fr;
    }

    .contact{
        display: none;
    }

    .text{
        font-size: 40px;
        text-align: center;
    }

    .btn{
        width: 25%;
        height: 40px;
        font-size: 12px;
    }
}

@media(max-width:480px){
    .item
    {
        grid-template-columns: 1fr;
    }

    .contact{
        display: none;
    }

    .text{
        font-size: 20px;
        text-align: center;
    }

    .btn{
        width: 20%;
        height: 40px;
        font-size: 12px;
    }
}