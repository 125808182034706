.main-service{
    /* border: 1px solid red; */
    height: 60rem;
    background-color: #e6e6fa;
    padding-top: 50px;
}

.main-second{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px;
    /* height: 20px; */
    margin-top: 50px;
}

.inside-one-main{
    border: 1px solid rgb(212, 212, 212);
    border-radius: 4px;
    padding: 10px;
    background-color: white;
}

.inside-two-main{
    border: 1px solid rgb(212, 212, 212);
    border-radius: 4px;
    padding: 10px;
    background-color: white;
}

.inside-three-main{
    border: 1px solid rgb(212, 212, 212);
    border-radius: 4px;
    padding: 10px;
    background-color: white;
}