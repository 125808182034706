.features-content{
    gap: 3rem;
    /* background-color: var(--blue-color); */
}

#features{
    /* border: 1px solid red; */
    background-color: var(--blue-color);
}

.services-main{
    background-color: var(--blue-color);
}

.features-item-icon img{
    max-width: 100px;
    /* border: 1px solid red; */
    border-radius: 50%;
    height: 100px;
}

.features-item-icon{
    margin-bottom: 2rem;
}

.features-item{
    padding: 2rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    transition: var(--transition);
    align-items: center;
    background-color: var(--white-color);
}

.features-item-text{
    /* border: 1px solid red; */
    font-family: cursive;
}


.features-item-text .text{
    max-width: 380px;
    /* font-family: cursive; */
    /* border: 1px solid red; */
}

.features-item:hover{
    /* background-color: var(--blue-color); */
    color: var(--red-color);
}

@media screen and (min-width: 578px){
    .features-item{
        display: flex;
        text-align: left;
    }

    .features-item-icon{
        margin-right: 3rem;
    }
}

@media screen and (min-width: 768px){
    .features-content{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and(min-width: 1200px){
    .features-content{
        grid-template-columns: repeat(3, 1fr);
    }
}

.section-title{
    /* border: 1px solid red; */
}

.slider-container{
    /* border: 1px solid blue; */
    margin-top: 60px;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel .slide {
  background-color: #f5f5f5; /* Background color for slides */
  text-align: center;
}

.carousel .slide img {
  max-width: 100%;
  height: 400px;
  object-fit: cover !important;
  margin: auto;
}

.carousel .control-prev,
.carousel .control-next {
  position: absolute;
  top: 50%;
  transform: translateY(0%);
  z-index: 2;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  /* border-radius: 50%; */
  font-size: 24px;
  color: #333;
  transition: background-color 0.3s;
}

.carousel .control-prev:hover,
.carousel .control-next:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.carousel .control-prev {
  left: 20px;
}

.carousel .control-next {
  right: 20px;
}
