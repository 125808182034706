.what-we-do-item{
    padding: 6rem 0;
    gap: 6rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

#what-we-do{
    /* border: 1px solid red; */
    background-color: #FAF0E6;
}

.main-about{
    background-color: #FAF0E6;
}

.what-we-do-item img{
    max-width: 420px;
    /* border-radius: 10px; */
}

@media screen and (min-width: 500px) and (max-width: 1000px){
    .what-we-do-item{
        /* border: 1px solid blue; */
    }
}

@media screen and (max-width: 450px){
    .what-we-do-item img{
        /* border: 1px solid red; */
    }
}

.what-we-do-item .text{
    max-width: 580px;
}

.what-we-do-item p {
    /* border: 1px solid red; */
    font-size: 1.5rem;
  align-items: center;
  text-align: justify;
  width: fit-content;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

@media screen and (min-width: 992px){
    .what-we-do-item{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: left;
    }

    .what-we-do-item:nth-child(even) .what-we-do-item-left{
        order: 2;
    }
}

/* .something-border-left{
    border: 2px solid rgb(73, 55, 172);
    height: 28rem;
    border-radius: 20px;
    width: 33.5% !important;
    position: absolute;
    margin-left: 20px !important;
    margin-top: 20px !important;
} */