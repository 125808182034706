.header{
    flex-direction: column;
    background-color: var(--red-color);
}

#header{
    /* border: 1px solid green; */
}

.header-content{
    row-gap: 6rem;
    min-height: calc(100vh - 80px);
}

.header-content-right img{
    max-width: 800px;
    border-radius: 10px;
}

.header-title{
    margin-bottom: 2.5rem;
}

@media screen and (min-width: 992px){
    .header-content{
        grid-template-columns: 2fr 3fr;
        align-items: center;
        text-align: left;
        column-gap: 6rem;
    }
}

.header{
background: #20002c; 
background: -webkit-linear-gradient(to right, #cbb4d4, #20002c);
background: linear-gradient(to right, #cbb4d4, #20002c); 
}

.download-btns{
    /* border: 1px solid red; */
    position: absolute;
    /* bottom: 0; */
    right: 0;
    margin-top: 300px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 5px;
}

.download-btns h1{
    /* border: 1px solid black; */
    margin: 0 auto;
    text-align: center;
    color: whitesmoke;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 300;
    /* background-color: #20002c; */
    width: 100%;
}

@media screen and (min-width: 200px) and (max-width: 1200px){
    .download-btns{
        /* border: 1px solid red; */
        position: absolute;
        /* bottom: 0; */
        right: 0;
        margin-top: 100px !important;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

@media screen and (min-width: 200px) and (max-width: 700px){
    .download-btns{
        /* border: 1px solid blue; */
        width: 100% !important;
        gap: 10px !important;
    }

    .store-btn{
        position: relative;
        width: 100% !important;
        height: 100px;
        border: none;
        border-radius: 5px;
        overflow: hidden;
        /* border: 1px solid red !important; */
    }
    
    .store-btn .btn-effect{
        position: absolute;
        top: 0;
        left: 0;
        width: 105px;
        height: 100px;
    }

    .store-btn .icon{
        position: absolute;
        top: 0;
        left: 0;
        width: 30px !important;
        height: 30px !important;
    }

    .store-btn .text-box{
        position: relative !important;
        top: 0;
        right: 0;
        width: 225px;
        height: 100px;
        color: #fff;
        letter-spacing: 1px;
        text-align: left;
    }

    .store-btn .text-box .txt-1{
        font-size: 12px !important;
        font-weight: 600;
        margin-left: 100px !important;
        padding: 25px 0 0 25px;
        text-transform: uppercase;
    }
    
    .store-btn .text-box .txt-2{
        font-size: 25px !important;
        font-weight: 500px;
        margin-left: 100px !important;
        padding: 0 0 25px 25px;
    }
}

.store-btn{
    position: relative;
    width: 250px;
    height: 60px;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    /* border: 1px solid red; */
}

.store-btn .btn-effect{
    position: absolute;
    top: 0;
    left: 0;
    width: 325px;
    height: 100px;
}

.store-btn .btn-effect::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 225px;
    height: 100px;
    background: linear-gradient(135deg, #99D98C, #76C839);
}

.store-btn .btn-effect::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background: linear-gradient(135deg, #76C893, #52B69A);
    transition: .5s;
}

.store-btn:hover .btn-effect::after{
    width: 100%;
}

.store-btn .icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    /* border: 1px solid red; */
}

.store-btn .icon p{
    font-size: 23px;
    line-height: 65px;
    color: #fff;
    transition: .5s;
}

.store-btn:hover .icon p{
    color: #99D98C;
    text-shadow: 0 0 10px #FFF, 0 0 20px
    #99D98C, 0 0 30px #fff, 0 0 40px
    #99D98C, 0 0 50px #fff, 0 0 60px
    #99D98C, 0 0 70px #fff, 0 0 80px
    #99D98C, 0 0 90px #fff, 0 0 100px
    #99D98C;
}

.store-btn .text-box{
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    height: 80px;
    color: #fff;
    letter-spacing: 1px;
    text-align: left;
    /* border: 1px solid blue; */
}

.store-btn .text-box .txt-1{
    font-size: 10px;
    font-weight: 600;
    margin: 0;
    padding: 15px 0 0 20px;
    text-transform: uppercase;
}

.store-btn .text-box .txt-2{
    font-size: 20px;
    font-weight: 500px;
    margin: 0;
    padding: 0 0 25px 18px;
}

.main-banner{
    width: 100%;
    height: 500px;
    /* border: 1px solid red; */
}

.main-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border: 2px solid blue; */
}