.terms-container {
    /* max-width: 800px; */
    /* margin: 0 auto; */
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #FFFAF0;
  }
  
  .terms-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-container h2 {
    margin-top: 20px;
  }
  
  .terms-container p {
    margin: 10px 0;
  }
  
  .terms-container ul {
    list-style-type: disc;
    margin: 10px 0 10px 20px;
  }
  
  .terms-container ul li {
    margin-bottom: 10px;
  }
  
  .terms-container ul li ul {
    list-style-type: circle;
    margin: 10px 0 10px 20px;
  }
  