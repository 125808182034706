body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Noto+Sans+Display:wght@300&family=Poppins&family=Quicksand:wght@300..700&family=Raleway+Dots&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto&family=Roboto+Slab&display=swap');

:root{
  --font-base: 'Raleway', sans-serif;
  --red-color: #f26c4f;
  --blue-color: #E6E6FA;
  --dark-color: #464646;
  --light-gray: #f4f4f4;
  --white-color: #fff;
  --transition: all 100ms ease-in-out;
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html{
  font-size: 10px;
}

body{
  font-size: 1.8rem;
  line-height: 1.6;
  font-family: var(--font-base);
  color: var(--dark-color);
}

a{
  color: unset;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

img{
  width: 100%;
  display: block;
}

button{
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1.8rem;
}

input, textarea{
  outline: 0;
  border: none;
  resize: none;
}

h1, h2, h3, h4{
  line-height: 1.5;
  letter-spacing: 1px;
}