.navbar{
    background-color: var(--red-color);
}

.brand-and-toggler{
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
}

.navbar-brand{
    align-items: center;
}

.navbar-brand span:first-child{
    display: inline-block;
    border: 3px solid var(--white-color);
    padding: 0 1.2rem !important;
    margin-right: 1rem;
    transition: var(--transition);
}

.navbar-brand span:last-child{
    transition: var(--transition);
}

.navbar-brand:hover span:first-child{
    background-color: var(--white-color);
    color: var(--red-color);
}

.navbar-brand:hover span:last-child{
    color: var(--white-color);
}

.navbar-collapse{
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--dark-color);
    height: 100%;
    width: 100%;
    padding: 4rem;
    transform: translateX(100%);
    transition: var(--transition);
    z-index: 5;
}

.show-navbar{
    transform: translate(0);
}

.navbar-collapse .navbar-hide-btn{
    position: absolute;
    right: 2rem;
    top: 2rem;
    transition: var(--transition);
}

.navbar-collapse ,.navbar-hide-btn:hover{
    opacity: 0.9;
}

.navbar-collapse .nav-item{
    margin: 2rem 0;
}

.navbar-collapse .nav-link{
    transition: var(--transition);
    display: inline-block;
}

.navbar-collapse .nav-link:hover{
    transform: scale(1.2);
}

@media screen and (min-width: 480px){
    .navbar-collapse{
        width: 320px;
    }
}

@media screen and (min-width: 992px){
    .navbar-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .brand-and-toggler{
        width: auto;
    }
    .navbar-collapse{
        width: 100%;
        position: relative;
        transform: translateX(0);
        padding: 0;
        justify-content: flex-end;
        background-color: transparent;
    }
    .navbar-nav{
        display: flex;
        align-items: center;
    }
    .navbar-collapse .nav-link{
        font-size: 16px;
    }
    .navbar-collapse .nav-link:hover{
        transform: none;
        color: var(--dark-color);
    }
    .navbar-collapse .nav-item{
        margin: 0 0 0 2.4rem;
    }
    .navbar-hide-btn, .navbar-show-btn{
        display: none;
    }
}

.navbar{
    background-color: black;
}

.nav-link{
    /* color: black; */
}
