.footer-item-group{
    gap: 3rem;
}

.footer-item-title{
    margin-bottom: 2rem;
}

.footer-item-links li{
    margin-bottom: 1rem;
}

.footer-item-links li a{
    transition: var(--transition);
    opacity: 0.8;
}

.footer-item-links li a:hover{
    opacity: 1;
}

.footer-social-icons{
    margin: 4rem;
}

.footer-social-icons a{
    margin: 0 1rem;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    transition: var(--transition);
}

.footer-social-icons a:hover{
    background-color: var(--white-color);
    color: var(--red-color);
}

.foot-last-text{
    /* border: 1px solid red; */
    height: 25px;
}

.foot-last-text p:hover{
    border-bottom: 1px solid white;
    cursor: pointer;
}

@media screen and (min-width: 768px){
    .footer-item-group{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 992px){
    .footer-item-group{
        grid-template-columns: repeat(4, 1fr);
    }

    .footer-item{
        text-align: left;
    }
}
