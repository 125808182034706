.projects-content{
    gap: 6rem;
}

.projects-item{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    min-height: 260px;
    justify-content: space-between;
    transition: var(--transition);
}

.projects-item:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.projects-item img{
    max-width: 320px;
}

.projects-item h4{
    opacity: 0.7;
}

@media screen and (min-width: 600px){
    .projects-content{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 992px){
    .projects-content{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1200px){
    .projects-content{
        grid-template-columns: repeat(4, 1fr);
    }
}